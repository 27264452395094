// React
import { React, useContext, useState } from 'react';

// MUI
import { Box, IconButton } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import { keyframes } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { grey } from '@mui/material/colors';

// Misc
import AudioRecorderPolyfill from 'audio-recorder-polyfill';
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { Context } from '../context/context';
import useAPI from '../hooks/use-api';
import useLanguage from '../hooks/use-language';

const RecordingIconButton = (props) => {
    const {
        chatInputContext,
        errorStateContext,
        isRecordingContext,
        loadingContext,
    } = useContext(Context);

    const [ , setChatInput ] = chatInputContext;
    const [ , setErrorState ] = errorStateContext;
    const [ , setLoading ] = loadingContext;
    const [ , setIsRecording ] = isRecordingContext;
    const [ uploadProgress, setUploadProgress ] = useState(0);

    const { api } = useAPI();
    const { language } = useLanguage(['en', 'sv']);

    const glowingKeyframes = keyframes`
        0% {
            background-color: #d32f2f;
            box-shadow: 0 0 3px #d32f2f;
        }
        50% {
            background-color:#d32f2f;
            box-shadow: 0 0 15px #d32f2f;
        }
        100% {
            background-color: #d32f2f;
            box-shadow: 0 0 3px#d32f2f;
        }
    `;

    const handleNotAllowedOrFoundError = () => {
        console.warn("Access to microphone not granted by user");
        setErrorState({
            error: true,
            message: {
                'en': 'You have to enable your microphone for recording to work.',
                'sv': 'Du måste aktivera din mikrofon för att inspelningen ska fungera.',
            }[language],
        });
        setLoading(false);
        setIsRecording(false);
    }

    // @xxx
    // There is a known problem with the Whisper API rejecting MP4-encoded audio recorded by Safari (AAC audio?).
    // To overcome this we use a polyfill and force MP3-encoding for all instances of Safari.
    // Safari is the only browser which supports MP4 so this should be more robust than using a simple browser check
    // or introducing a new dependency, and the worst case is we use the polyfill even for browsers which don't
    // exhibit the problem. Once this is solved upstream and works natively the polyfill can be safely removed again.
    const isSafari = MediaRecorder.isTypeSupported('audio/mp4');
    if (isSafari) {
        AudioRecorderPolyfill.encoder = mpegEncoder;
        AudioRecorderPolyfill.prototype.mimeType = 'audio/mpeg';
        window.MediaRecorder = AudioRecorderPolyfill;
    }

    const rec = useAudioRecorder({}, handleNotAllowedOrFoundError);

    const toggleRecording = () => {
        if (rec.isRecording) {
            //console.log("rec -> stop");
            rec.stopRecording();
            setIsRecording(false);
        }
        else {
            //console.log("stop -> rec");
            setLoading(true);
            setIsRecording(true);
            setChatInput('');
            rec.startRecording();
        }
    }

    const uploadRecording = async (blob) => {
        const formData = new FormData();
        formData.append("speech", blob);

        try {
            setErrorState({ error: false });
            setLoading(true);
            const response = await api.post('/speech', formData, {
                onUploadProgress: (e) => {
                    // the callback seems to return 100% completion "long" before the upload process
                    // is completely finished, so we insist on it being 99% at most to avoid showing 100%
                    // to the end user in the UI before the request is actually done
                    let percentageCompleted = Math.round(Math.abs(((e.loaded * 100) / e.total) - 0.6));

                    setUploadProgress(percentageCompleted);
                }
            });

            setChatInput(response.data?.text || '');
        } catch (error) {
            setErrorState({ error: true, message: error });
            setChatInput('');
            console.error("Speech to text failed. Reason was: " + error);
        }

        setUploadProgress(0);
        setLoading(false);
    };

    return (
        <>
            <Box style={{ display: 'none' }}>
                <AudioRecorder
                    onRecordingComplete={uploadRecording}
                    recorderControls={rec}
                />
            </Box>
            {uploadProgress > 0
                ?
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress variant="indeterminate" />
                        <IconButton
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            disabled
                        >
                            <MicIcon />
                        </IconButton>
                    </Box>
                :
                    <IconButton
                        sx={{
                            animation: rec.isRecording ? `${glowingKeyframes} 1300ms infinite` : 'none',
                        }}
                        {...props}
                        onClick={toggleRecording}
                        disabled={props.disabled && !rec.isRecording}
                    >
                        {rec.isRecording
                            ?
                                <StopIcon sx={{ color: grey[200] }}/>
                            :
                                <MicIcon />
                        }
                        <Box component="span" sx={visuallyHidden}>Spela in frågan</Box>
                    </IconButton>
            }
        </>
    );
}

export default RecordingIconButton;
