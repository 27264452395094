// React
import React, { useContext } from "react";

// MUI
import { Button } from "@mui/material";

// Misc
import { Context } from "../context/context";
import DocumentViewer from "../components/document-viewer";

const DocumentArea = (props) => {
    const { previewDocumentContext } = useContext(Context);
    const [ previewDocument, setPreviewDocument ] = previewDocumentContext;

    return (
        <>
            {/*
            <div
                id="document-header"
                style={{
                    border: "10px solid yellow",
                    padding: "10px",
                }}
            >
                DOCUMENT HEADER
            </div>
            */}
            <div
                id="document-content"
                style={{
                    //border: "10px solid orange",
                    height: "100%",
                    overflow: "hidden",
                }}
            >
                <DocumentViewer
                    file={previewDocument}
                />
            </div>
            <div
                style={{
                    //border: "10px solid orange",
                    display: "flex", flexShrink: 0,
                    padding: "10px",
                }}
            >
                <Button
                    variant="contained"
                    size="large"
                    sx={{
                        width: '100%',
                        height: '56px',
                    }}
                    onClick={() => setPreviewDocument(null)}
                >
                    Stäng
                </Button>
            </div>
        </>
    )
}

export default DocumentArea;
