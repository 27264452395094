// React
import React, { useContext } from "react";

// MUI
import { Box, IconButton } from "@mui/material";
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { visuallyHidden } from "@mui/utils";

// Misc
import { Context } from "../context/context";

const VotingButtons = (props) => {
    const { loadingContext } = useContext(Context);
    const [ loading ] = loadingContext;

    return (
        <>
            {!props.reaction || props.reaction === 0
                ?
                    <>
                        <IconButton
                            disabled={loading}
                            onClick={(e) => props.onClick(props, 1)}
                            sx={{
                                color: 'text.secondary',
                                '&:hover': {
                                    color: 'primary.main',
                                }
                            }}
                        >
                            <ThumbUpOutlinedIcon fontSize="small" />
                            <Box component="span" sx={visuallyHidden}>Upprösta</Box>
                        </IconButton>
                        <IconButton
                            disabled={loading}
                            onClick={(e) => props.onClick(props, -1)}
                            sx={{
                                color: 'text.secondary',
                                '&:hover': {
                                    color: 'primary.main',
                                }
                            }}
                        >
                            <ThumbDownOutlinedIcon fontSize="small" />
                            <Box component="span" sx={visuallyHidden}>Nedrösta</Box>
                        </IconButton>
                    </>
                :
                    <IconButton
                        disabled={loading}
                        color="primary"
                        onClick={(e) => props.onClick(props, props.reaction)}
                    >
                        {props.reaction === 1
                            ?
                                <>
                                    <ThumbUpIcon fontSize="small" />
                                    <Box component="span" sx={visuallyHidden}>Uppröstat</Box>
                                </>
                            :
                                <>
                                    <ThumbDownIcon fontSize="small" />
                                    <Box component="span" sx={visuallyHidden}>Nedröstat</Box>
                                </>
                        }
                    </IconButton>
            }
        </>
    )
}
export default VotingButtons;
