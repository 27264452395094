// MUI
import { styled, keyframes } from '@mui/system';
import { useTheme } from "@emotion/react";
import { alpha } from "@mui/material";


const typingDot = keyframes`
    15% {
        transform: translateY(-35%);
        opacity: 0.5;
    }
    30% {
        transform: translateY(0%);
        opacity: 1;
    }
`;

const TypingIndicator = (props) => {
    const theme = useTheme();

    const Dot = styled('span')({
        height: "6px",
        width: "6px",
        margin: "1px",
        borderRadius: "100%",
        display: "inline-block",
        backgroundColor: alpha(theme.palette.text.primary, 0.3),
        animation: `1.2s ${typingDot} ease-in-out infinite`,
        "&:nth-of-type(2)": {
            animationDelay: "0.15s",
        },
        "&:nth-of-type(3)": {
            animationDelay: "0.25s",
        }
    });

    return (
        <>
            <Dot />
            <Dot />
            <Dot />
        </>
    );
}

export default TypingIndicator;
