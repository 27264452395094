// React
import { useEffect, useState } from "react";

// MSAL
import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";

// Misc
import config from "../config.json";

const acquireAccessToken = async () => {
    const enableMSAL = !!config.MSAL?.auth?.clientId;

    if (!enableMSAL) {
        return;
    }

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return;
    }

    const authResult = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    return authResult.accessToken;
}

const useAccessToken = () => {
    const [ accessToken, setAccessToken ] = useState(null);

    useEffect(() => {
        const _acquireAccessToken = async () => {
            const accessToken = await acquireAccessToken();
            setAccessToken(accessToken);
        }
        _acquireAccessToken();
    }, []);

    return { accessToken };
}

export default useAccessToken;
