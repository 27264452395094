// React
import React, { useState } from "react";

// Misc
import config from '../config.json';

export const Context = React.createContext();

export const ContextProvider = ({ children }) => {
    const [ conversationId, setConversationId ] = useState(null);
    const [ previewDocument, setPreviewDocument ] = useState(null);
    const [ openFeedbackDialog, setOpenFeedbackDialog ] = useState(false);
    const [ chatInput, setChatInput ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ isRecording, setIsRecording ] = useState(false);
    const [ errorState, setErrorState ] = useState({error: false, message: ''});
    const [ userInfo, setUserInfo ] = useState({});
    const [ welcomeQuestions, setWelcomeQuestions ] = useState(config.WELCOME?.QUESTIONS || []);
    const [ messages, setMessages ] = useState([]);
    const [ pendingFeedback, setPendingFeedback ] = useState({});

    return (
        <Context.Provider
            value={{
                messagesContext:               [ messages,               setMessages ],
                previewDocumentContext:        [ previewDocument,        setPreviewDocument ],
                conversationIdContext:         [ conversationId,         setConversationId ],
                openFeedbackDialogContext:     [ openFeedbackDialog,     setOpenFeedbackDialog ],
                chatInputContext:              [ chatInput,              setChatInput ],
                loadingContext:                [ loading,                setLoading ],
                isRecordingContext:            [ isRecording,            setIsRecording ],
                errorStateContext:             [ errorState,             setErrorState ],
                userInfoContext:               [ userInfo,               setUserInfo ],
                welcomeQuestionsContext:       [ welcomeQuestions,       setWelcomeQuestions ],
                pendingFeedbackContext:        [ pendingFeedback,        setPendingFeedback ],
            }}
        >
            {children}
        </Context.Provider>
    );
};
