// React
import { React, useContext } from 'react';

// MUI
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

// Misc
import { Context } from '../context/context';
import FeedbackInput from './feedback-input';

const FeedbackDialog = (props) => {
    const {
        pendingFeedbackContext,
        openFeedbackDialogContext,
    } = useContext(Context);
    const [ pendingFeedback ] = pendingFeedbackContext;
    const [ openFeedbackDialog, setOpenFeedbackDialog ] = openFeedbackDialogContext;

    const handleClose = () => {
        setOpenFeedbackDialog(false);
    };

    return (
        <Dialog
            open={openFeedbackDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            PaperProps={{
                sx: {
                    padding: "20px",
                }
            }}
        >
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    "& > svg": {
                        margin: "0 12px 0 0",
                        verticalAlign: "middle",
                    }
                }}
            >
                {pendingFeedback?.vote === 1
                    ?
                        <ThumbUpIcon color="primary" />
                    :
                        <ThumbDownIcon color="primary" />
                }
                Skriv gärna en kommentar
            </DialogTitle>
            <DialogContent>
                <FeedbackInput />
            </DialogContent>
        </Dialog>
    );
}

export default FeedbackDialog;
