// MUI
import { styled } from "@mui/material";

// Misc
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import config from '../config.json';
import useAccessToken from '../hooks/use-accesstoken';
import { useTheme } from "@emotion/react";

const DocumentViewer = (props) => {
    const { accessToken } = useAccessToken();

    const theme = useTheme();

    const StyledDocViewer = styled(DocViewer)((props) => ({
        '&, & #header-bar': {
            'background-color': theme.palette.background.default + ' !important',
        },
        '& #file-name, & #pdf-page-info': {
            color: theme.palette.text.primary,
        },
        '& #pdf-controls': { // disable document control bar (navigation, zoom, scroll mode etc.)
            display: 'none',
        },
        '& .react-pdf__Page__textContent.textLayer': { // disable text overlay, user may not copy text
            display: 'none',
        },
    }));

    const doc = [
        { uri: config.SERVER_URL + '/content/' + props.file }, // Remote file
    ];

    return (
        <StyledDocViewer
            documents={doc}
            pluginRenderers={DocViewerRenderers}
            requestHeaders={accessToken ? {'Authorization': 'Bearer ' + accessToken} : {}}
            config={{
                pdfVerticalScrollByDefault: true
            }}
        />
    )
}
export default DocumentViewer;
