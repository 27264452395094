// React
import { useContext, useEffect } from "react";

// MSAL
import {
    useMsal,
} from "@azure/msal-react";

// Misc
import { Context } from "../context/context";

const MsalUserInfo = () => {
    const { userInfoContext } = useContext(Context);
    const [ , setUserInfo ] = userInfoContext;

    const { instance, accounts } = useMsal();

    useEffect(() => {
        if (!instance) {
            return;
        }

        const account = instance.getActiveAccount();
        if (!account) {
            return;
        }

        setUserInfo((prev) => {
            const newUserInfo = {
                ...prev,
                fullName: account.name,
                username: account.username,
                // id: account.homeAccountId, // @xxx homeAccountId vs. localAccountId?
            }

            return newUserInfo;
        })
    }, [instance, accounts, setUserInfo]);

    return;
}

export default MsalUserInfo;
