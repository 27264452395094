// React
import { useContext, useRef } from "react";

// MUI
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { visuallyHidden } from '@mui/utils';

// Misc
import { Context } from "../context/context";
import useAPI from '../hooks/use-api';

const FeedbackInput = () => {
    const commentRef = useRef('');
    const {
        pendingFeedbackContext,
        messagesContext,
        openFeedbackDialogContext,
        conversationIdContext,
    } = useContext(Context);
    const [ messageList, setMessageList ] = messagesContext;
    const [, setOpenFeedbackDialog ] = openFeedbackDialogContext;
    const [ conversationId ] = conversationIdContext;
    const [ pendingFeedback ] = pendingFeedbackContext;

    const { api } = useAPI();

    const submitFeedbackComment = async () => {
        const feedbackComment = commentRef.current.value

        const newMessageList = messageList.map((obj, i) => {
            if (i === pendingFeedback.messageIndex) {
                return { ...obj, comment: feedbackComment };
            }
            return obj;
        });

        try {
            await api.post('/reaction', {
                "conversation_id": conversationId,
                "history": newMessageList,
            });

            setMessageList(newMessageList);
        } catch (error) {
            console.error('Failed to send feedback comment. Reason: ' + error);
        }

        setOpenFeedbackDialog(false);
    }

    return(
        <TextField
            inputRef={commentRef}
            fullWidth
            multiline
            defaultValue={messageList[pendingFeedback?.messageIndex].comment}
            onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                    submitFeedbackComment();
                    ev.preventDefault();
                }
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton 
                            edge="end"
                            color="primary"
                            onClick={() => submitFeedbackComment()}
                        >
                            <SendIcon/>
                            <Box component="span" sx={visuallyHidden}>Skicka Feedback</Box>
                        </IconButton>
                    </InputAdornment>
                    ),
            }}>
        </TextField>
    )
}

export default FeedbackInput;
