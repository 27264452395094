// React
import { React, useContext, useState } from 'react';

// MUI
import { Box, IconButton } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { visuallyHidden } from "@mui/utils";

// Misc
import { Context } from '../context/context';
import useAPI from "../hooks/use-api";
import config from "../config.json";

const FileUploadIconButton = (props) => {
    const {
        errorStateContext,
        loadingContext,
    } = useContext(Context);
    const [ , setErrorState ] = errorStateContext;
    const [ , setLoading ] = loadingContext;
    const [ uploadProgress, setUploadProgress ] = useState(0);

    const { api } = useAPI();

    const allowedFileExtensions = config.ALLOWED_FILE_EXTENSIONS || [];

    const handleUploadSelectedFiles = async (event) => {
        const selectedFiles = event.target.files;
        if (!selectedFiles) {
            return;
        }

        //console.log(selectedFiles);

        const formData = new FormData();

        Array.from(selectedFiles).forEach((file) => {
            const fileExtension = file.name.split('.').pop();

            if (!allowedFileExtensions.includes(fileExtension)) {
                console.warn(`Rejecting upload of file '${file.name}' (reason is: disallowed file extension)`, file);
                return;
            }
            formData.append("file", file);
        });

        //console.log(`Uploading ${Array.from(formData.keys()).length}/${Array.from(selectedFiles).length} files`);

        try {
            setErrorState({ error: false });
            setLoading(true);
            /* const response = */ await api.post('/upload', formData, {
                onUploadProgress: (e) => {
                    // the callback seems to return 100% completion "long" before the upload process
                    // is completely finished, so we insist on it being 99% at most to avoid showing 100%
                    // to the end user in the UI before the request is actually done
                    let percentageCompleted = Math.round(Math.abs(((e.loaded * 100) / e.total) - 0.6));

                    //console.log(percentageCompleted);
                    setUploadProgress(percentageCompleted);
                }
            });

            //console.log("File upload successful", formData);
        } catch (error) {
            setErrorState({ error: true, message: error });
            console.error("File upload unsuccessful. Reason was: " + error);
        }

        setUploadProgress(0);
        setLoading(false);
    };

    return (
        <>
            {uploadProgress > 0
                ?
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress variant="indeterminate" />
                        <IconButton
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            color="primary"
                            disabled
                        >
                            <FileUploadIcon />
                        </IconButton>
                     </Box>
                :
                    <IconButton
                        component="label"
                        //onClick={}
                        color="primary"
                        disabled={props.disabled}
                    >
                        <FileUploadIcon />
                        <input
                            type="file"
                            accept={allowedFileExtensions.map((ext) => { return "." + ext; }).join(', ')}
                            onChange={handleUploadSelectedFiles}
                            multiple
                            style={{
                                display: 'none',
                            }}
                        />
                        <Box component="span" sx={visuallyHidden}>Ladda upp filer</Box>
                    </IconButton>
            }
        </>
    );
}

export default FileUploadIconButton;
