// React
import React from "react";
import { ThemeProvider } from "@emotion/react";

// MUI
import { CssBaseline, alpha, createTheme } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

// MSAL
import { MsalProvider } from '@azure/msal-react';

// Misc
import Main from "./layout/main";
import MsalUserInfo from "./components/msal-userinfo"
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import config from './config.json'
import webManifest from './manifest.json'

function useTitle(title) {
  React.useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = prevTitle;
    }
  })
}

function useFaviconURL(url) {
  React.useEffect(() => {
    const faviconElement = document.querySelector('link[rel=icon]');
    const prevFaviconURL = faviconElement.href;
    faviconElement.href = url;
    return () => {
      faviconElement.href = prevFaviconURL;
    }
  })
}

function App({msalInstance}) {
  const ct = config.THEME;

  const appName = config.AGENT?.NAME ? config.AGENT?.NAME : 'AI Assistant';

  useTitle(config.TAB_TITLE || appName);
  useFaviconURL(config.FAVICON_URL || '');

  // allow configuring dark mode in config.json
  // config.THEME.DARKMODE can be one of the following:
  //   'system': use system (browser) setting (aka auto mode)
  //   'never':  always use light mode
  //   'always': always use dark mode
  //   unset:    same as 'system' (default)
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const useDarkMode=
    (function() {
      switch (ct?.DARKMODE) {
        case 'system':
          return prefersDarkMode;
        case null:
          return prefersDarkMode;
        case 'never':
          return false;
        case 'always':
          return true;
        default:
          return prefersDarkMode;
      }
    })();
  const supportedColorSchemes=
    (function() {
      switch (ct?.DARKMODE) {
        case 'never':
          return 'light only';
        case 'always':
          return 'dark only';
        default:
          return 'light dark';
      }
    })();
  const mode = useDarkMode ? 'dark' : 'light';

  // disable shadows globally, see https://stackoverflow.com/a/69805231
  const defaultTheme = createTheme();
  const noShadows = [...defaultTheme.shadows].map(() => 'none');

  const themePrimaryMain = ct?.PRIMARY?.MAIN || '#008a00';

  const theme = createTheme({
    palette: {
      mode: mode,
      text : {
        ...(mode === 'light'
          ? {
          }
          : {
            primary: '#ececf1',
          }
        )
      },
      primary: {
        main: themePrimaryMain,
        ...(mode === 'light'
          ? {
            light: ct?.PRIMARY?.LIGHT || alpha(themePrimaryMain, 0.1)
          }
          : {
            light: alpha(themePrimaryMain, 0.1)
          }
        ),
      },
      background: {
        ...(mode === 'light'
          ? {
            default: ct?.BACKGROUND?.DEFAULT || '#fff',
            outer: ct?.BACKGROUND?.OUTER || alpha(themePrimaryMain, 0.07),
            paper: ct?.BACKGROUND?.DEFAULT || '#fff'
          }
          : {
            default: '#343541',
            outer: '#121212',
            paper: '#343541'
          }
        )
      },
    },
    typography: {
      footer: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    shadows: noShadows,
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            footer: 'p',
          },
        },
      },
    },
  });

  document.querySelector('meta[name="theme-color"]').setAttribute("content", theme.palette.primary.main);
  document.querySelector('meta[name="color-scheme"]').setAttribute("content", supportedColorSchemes);

  // relative paths (start_url, src, ...) won't work because they would be interpreted as relative to our
  // dynamic blob: URL, so we force absolute URLs (based on the window location reported by the browser)
  // for all necessary attributes.
  // wrt/ icon src attributes, we prepend the URL only if they don't include a scheme and chip an optional leading slash
  const site_root_url = new URL('/', window.location.origin).href;
  let finalWebManifest = {
    ...webManifest,
    "name": appName,
    "short_name": appName,
    "start_url": site_root_url,
    "theme_color": theme.palette.primary.main,
    "background_color": theme.palette.background.default,
  };

  finalWebManifest.icons.forEach((icon) => {
    if (!icon.src.includes('://')) {
      icon.src = site_root_url + icon.src.replace(/^\//, '');
    }
  });

  const stringWebManifest = JSON.stringify(finalWebManifest);
  const blob = new Blob([stringWebManifest], {type: 'application/json'});
  const webManifestURL = URL.createObjectURL(blob);
  document.querySelector('#webmanifest').setAttribute('href', webManifestURL);

  return (
    <MsalProvider instance={msalInstance}>
      <MsalUserInfo />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Main />
      </ThemeProvider>
    </MsalProvider>
  );
}

export default App;
